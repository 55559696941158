


























































































































































.tools-content{
  padding: 10px 0;
  border-bottom: 1px #ccc solid;
  .tools-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;

    .input-wrapper {
      position: relative;

      > input {
        width: 400px;
        height: 35px;
        background-color: transparent;
        border: 1px #ccc solid;
        border-radius: 17px;
        box-sizing: border-box;
        outline: none;
        padding-left: 20px;
      }

      > i {
        width: 23px;
        height: 23px;
        position: absolute;
        top: 6px;
        right: 12px;
        display: inline-flex;
        background: url("../assets/img/search-btn.png") no-repeat center;
      }
    }

    .router-wrapper {
      display: flex;
      align-items: center;

      > * {
        margin-right: 5px;
        color: #BBBCBC;
        font-size: 16px;
      }

      > i {
        width: 23px;
        height: 23px;
        display: inline-flex;
        background: url("../assets/img/home.png") no-repeat center;
      }

      > a{
        transition: color 0.2s linear;
        &:hover{
          color: #333;
        }
      }
    }
  }
}
section {
  main {
    > div {
      width: 1200px;
      margin: 0 auto;

      &:nth-child(1) {

        > ul {
          li {
            border-bottom: 1px dashed #b4b4b4;
            margin: 30px 0;
          }
          .news-list-stick {
            display: flex;
            justify-content: space-between;
            padding: 30px 0;

            > div {
              &:nth-child(1) {
                flex: 0 0 520px;

                > img {
                  width: 470px;
                  height: 260px;
                }
              }

              &:nth-child(2) {
                position: relative;

                > div {
                  > h2 {
                    font-size: 26px;
                    color: #333;
                    line-height: 34px;
                    font-weight: normal;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }

                  > div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 10px;

                    > i {
                      width: 22px;
                      height: 22px;
                      background: url("../assets/img/clock.png") no-repeat center;
                    }

                    > span {
                      height: 22px;
                      line-height: 22px;
                      font-size: 14px;
                      color: #b4b4b4;
                      margin-left: 5px;
                    }
                  }
                }

                > p {
                  font-size: 14px;
                  color: #666;
                  line-height: 25px;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 4;
                  -webkit-box-orient: vertical;
                }

                > a {
                  background-color: #000096;
                  font-size: 13px;
                  padding: 10px 30px;
                  color: #fff;
                  position: absolute;
                  bottom: 0;
                  right: 0;
                }
              }
            }
          }
          .new-list {
            > div {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              > div {
                &:nth-child(1) {
                  > a {
                    > h3 {
                      font-size: 24px;
                      color: #333;
                      line-height: 1.1;
                      font-weight: normal;
                      width: auto;
                    }
                  }

                  > p {
                    font-size: 14px;
                    color: #b4b4b4;
                    line-height: 25px;
                  }
                }

                &:nth-child(2) {
                  flex: 1 1 800px;
                  text-align: right;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  > i {
                    width: 22px;
                    height: 22px;
                    background: url("../assets/img/clock.png") no-repeat center;
                  }

                  > span {
                    height: 22px;
                    line-height: 22px;
                    font-size: 14px;
                    color: #b4b4b4;
                    margin-left: 5px;
                  }
                }
              }
            }
          }
        }
      }

      &:nth-child(2) {
        padding: 30px 0;
        > .container {
          justify-content: center;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .tools-content{
    .tools-wrapper{
      width: 100%;
    }
  }
  section{
    main{
      padding: 0 15px;
      >div:nth-child(1){
        width: 100%;
        >ul{
          >div{
            >li:nth-child(n){
              display: none;
            }
            >li:nth-child(1){
              display: block;
              >div:nth-child(1){
                img{
                  width: 100%;
                  height: auto;
                }
              }
              >div:nth-child(2){
                h2{
                  font-size: 18px;
                  line-height: 24px;
                }
                >p{
                  display: none;
                }
                >a{
                  position: relative;
                }
              }
            }
          }
          >li{
            >div{
              >div:nth-child(1){
                width: 100%;
                h3{
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
                p{
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
              >div:nth-child(2){
                display: none !important;
              }
            }
          }
        }
      }
      >div:nth-child(2){
        width: 100%;
      }
    }
  }
}
